import React from "react"
const { Component } = React;
import itemdata from "../data/guidelines.json"
import articles from "../data/articles.json"
import { FaSearch, FaBook } from 'react-icons/fa'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "../styles/tabstyles.css";
import { ReactTinyLink } from 'react-tiny-link';
import Loadable from "@loadable/component";
import paper1 from '../downloads/paper1.pdf';
import aftershock from '../downloads/aftershock_report.pdf';
import aftershockimage from "../images/aftershock.jpg"

const Linkpreview = Loadable(() => import('./linkpreviews'))

const Libdata = () => {
//SELECT FILTER FUNCTION
const searchoptionscountry = () => {
  if (typeof window !== `undefined`) {
var optioninput, optionfilter, ul, li, i, a, inputvalue;
  optioninput = document.getElementById('countryfilter');
  optionfilter = optioninput.options[optioninput.selectedIndex].value;
  ul = document.getElementById('myUL');
  li = ul.getElementsByTagName('li');

  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    inputvalue = a.textContent || a.innerText;
    if (inputvalue.indexOf(optionfilter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
}}
const searchoptionscountry2 = () => {
  if (typeof window !== `undefined`) {
var optioninput, optionfilter, ul, li, i, a, inputvalue;
  optioninput = document.getElementById('countryfilter2');
  optionfilter = optioninput.options[optioninput.selectedIndex].value;
  ul = document.getElementById('myUL2');
  li = ul.getElementsByTagName('li');

  for (i = 0; i < li.length; i++) {
    a = li[i].getElementsByTagName("a")[0];
    inputvalue = a.textContent || a.innerText;
    if (inputvalue.indexOf(optionfilter) > -1) {
      li[i].style.display = "";
    } else {
      li[i].style.display = "none";
    }
  }
}}

const searchoptionsspec = () => {
  if (typeof window !== `undefined`) {
  var optioninput, optionfilter2, ul, li, i, a, inputvalue;
    optioninput = document.getElementById('specfilter');
    optionfilter2 = optioninput.options[optioninput.selectedIndex].value;
    ul = document.getElementById('myUL');
    li = ul.getElementsByTagName('li');
  
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      inputvalue = a.textContent || a.innerText;
      if (inputvalue.indexOf(optionfilter2) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }}

//SORT BY...
//initially sort articles by newest
let sortedarticles = articles.sort((b, a) =>
  a.pubdate.split('/').reverse().join().localeCompare(b.pubdate.split('/').reverse().join()));
//sort protocols by name/date
const sortby = () => {
  if (typeof window !== `undefined`) {
  var optioninput, optionvalue, li, i;
  optioninput = document.getElementById('sortby');
    optionvalue = optioninput.options[optioninput.selectedIndex].value;
    var ul = document.getElementById('myUL');
    li = ul.getElementsByTagName('li');

     if (optionvalue == "sortbyname"){
      Array.from(ul.getElementsByTagName("li"))
        .sort((a, b) => a.textContent.localeCompare(b.textContent))
        .forEach(li => ul.appendChild(li));  

    } else if (optionvalue == "sortbydate"){        
      Array.from(ul.getElementsByTagName("li"))
        .sort(function(a, b)  
        { 
            var aa =  a.getAttribute('data-date'),  
                bb = b.getAttribute('data-date'); 
                var aaa = aa.split('/').reverse().join(),
                     bbb = bb.split('/').reverse().join();
                     return aaa > bbb ? -1 : (aaa < bbb ? 1 : 0)
        }) 
        .forEach(li => ul.appendChild(li));  
      }
    }}
//sort articles by date
    const sortby2 = () => {
      var optioninput, optionvalue, li, i;
  optioninput = document.getElementById('sortby2');
    optionvalue = optioninput.options[optioninput.selectedIndex].value;
    var ul = document.getElementById('myUL2');
    li = ul.getElementsByTagName('li');
      if (typeof window !== `undefined`) {
        if (optionvalue == "sortbydate"){        
          Array.from(ul.getElementsByTagName("li"))
            .sort(function(a, b)  
            { 
                var aa =  a.getAttribute('data-date'),  
                    bb = b.getAttribute('data-date'); 
                    var aaa = aa.split('/').reverse().join(),
                         bbb = bb.split('/').reverse().join();
                         return aaa > bbb ? -1 : (aaa < bbb ? 1 : 0)
            }) 
            .forEach(li => ul.appendChild(li));  
          }
      }}

//SEARCH FUNCTION
  const searchfunction = () => {
    if (typeof window !== `undefined`) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('myInput');
    filter = input.value.toUpperCase();
    ul = document.getElementById('myUL');
    li = ul.getElementsByTagName('li');
  
    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }}

  const searchfunction2 = () => {
    if (typeof window !== `undefined`) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('myInput2');
    filter = input.value.toUpperCase();
    ul = document.getElementById('myUL2');
    li = ul.getElementsByTagName('li');
  
    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }}

  // FILTER SEARCH BOXES 
  function getUnique(itemdata, comp) {
    const unique = itemdata
         .map(e => e[comp])
       // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => itemdata[e]).map(e => itemdata[e]);
     return unique;
  }

  function getUnique2(articles, comp) {
    const unique = articles
         .map(e => e[comp])
       // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => articles[e]).map(e => articles[e]);
     return unique;
  }

  const countries = require("../data/guidelines.json");
  const countries2 = require("../data/articles.json");
  const specs = require("../data/guidelines.json");
  const uniqueCountry = getUnique(countries, "Country");  
  const uniqueCountry2 = getUnique2(countries2, "Country");  
  const uniqueSpec = getUnique(specs, "Specialty");  
      
  return (
          <nav className="panel">
            <Tabs>
  <p className="panel-heading">
    PanSurg Digital Resource Library
  </p>
  
  <TabList style={{fontWeight: "bold"}}>
  <Tab>Guidelines</Tab>
  <Tab>Featured Articles</Tab>
  <Tab>PanSurg Articles</Tab>
  <Tab>Live Peer Reviews</Tab>
  <Tab>Videos</Tab>
  <Tab>Press</Tab>
</TabList>

<TabPanels>
<TabPanel>
<div className="panel-block">
    <p className="control has-icons-left">
      <input className="input" type="text" placeholder="Search for...Guidelines, Country, Specialty, Keywords..." id="myInput" onKeyUp={searchfunction} />
      <span className="icon is-left">
       <FaSearch />
      </span>
    </p>
  </div>
  <div className="panel-block" style={{overflowY:"auto"}}>
            <div className="select is-small">
            <select id="countryfilter" onChange={searchoptionscountry}>
              <option value="">Filter by Country (All)</option>
              {uniqueCountry.map(item => (
              <option value={item.Country}>
                {item.Country}
              </option>
            ))}
            </select></div>
              <span>&nbsp;&nbsp;</span>
              <div className="select is-small">
            <select id="specfilter" onChange={searchoptionsspec}>
              <option value="">Filter by Specialty (All)</option>
              {uniqueSpec.map(item => (
              <option value={item.Specialty}>
                {item.Specialty}
              </option>
            ))}
            </select>
            </div>
            <span>&nbsp;&nbsp;</span>
            <div className="select is-small" onChange={sortby}>
            <select id="sortby">
              <option value="">Sort By...</option>
              <option value="sortbyname">Sort by A-Z</option>
              <option value="sortbydate">Sort by Newest</option>
            </select>
            </div>
            <span>&nbsp;&nbsp;</span>
            
          </div>
          <div style={{maxHeight:700, overflowX : 'auto'}}>
  <ul id="myUL">
  {itemdata.map((data) => {
        return (
          <li data-date={data.Date}>
  <a href={data.link} className="panel-block">
  <span className="panel-icon">
        <FaBook /></span><span><a href={data.link}>{data.Title}</a>&nbsp;<i className="date is-size-7">{data.Date}</i>
        <span className="is-hidden">{data.Country + " " + data.Specialty + " " + data.tags}</span>
      </span>
  </a>
  </li> )
  })}
  </ul>
  </div>
  <br />
  </TabPanel>
  
<TabPanel>
<div className="panel-block">
    <p className="control has-icons-left">
      <input className="input" type="text" placeholder="Search for Articles" id="myInput2" onKeyUp={searchfunction2} />
      <span className="icon is-left">
       <FaSearch />
      </span>
    </p>
    </div>
    <div className="panel-block">
            <div className="select is-small" onChange={sortby2}>
            <select id="sortby2">
              <option value="">Sort By...</option>
              <option value="sortbydate">Sort by Newest</option>
            </select>
            </div>
            <span>&nbsp;&nbsp;</span>
            <div className="select is-small">
            <select id="countryfilter2" onChange={searchoptionscountry2}>
              <option value="">Filter by Country (All)</option>
              {uniqueCountry2.map(item => (
              <option value={item.Country}>
                {item.Country}
              </option>
            ))}
            </select></div>
  </div>
  <ul id="myUL2">
  {sortedarticles.map((data) => {
        return (
          <li data-date={data.pubdate}>
  <a href={data.link} className="panel-block">
  <span className="panel-icon">
        <FaBook /></span><span><a href={data.link}>{data.Title}</a>&nbsp;<i className="date is-size-7">{data.pubdate}</i>
        <span className="is-hidden">{data.Country + " " + data.Specialty + " " + data.tags}</span>
      </span>
  </a>
  </li> )
  })}
  </ul>
         <br />
          <span className="subtitle has-text-weight-bold has-text-red" style={{margin:"5%"}}>Aerosol and COVID19</span>
          <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://pubmed.ncbi.nlm.nih.gov/16002179/" className="has-text-weight-bold">Surgical smoke and infection control</a>
            </a>
            <a className="panel-block"> 
            <span className="panel-icon">
        <FaBook /></span>        
             <a href="https://www.medrxiv.org/content/10.1101/2020.03.09.20033217v2.full.pdf" className="has-text-weight-bold">Aerosol and surface stability of HCoV-19 (SARS-CoV-2)</a>
            </a>
            <a className="panel-block">   
            <span className="panel-icon">
        <FaBook /></span>       
            <a href="https://www.gastrojournal.org/article/S0016-5085(20)30282-1/pdf" className="has-text-weight-bold">Evidence for gastrointestinal infection of SARS-CoV-2</a>
            </a>
    </TabPanel>

    <TabPanel>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://link.springer.com/article/10.1007/s11695-020-05005-1" className="has-text-weight-bold">Impact of COVID-19 on Obesity Management Services in the United Kingdom (The COMS-UK study)</a>
            </a>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href={paper1} download="paper1.pdf" className="has-text-weight-bold">What has been the impact of COVID19 on safety culture? A case study from a large metropolitan Healthcare trust.</a>
            </a>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://europepmc.org/article/med/32730222" className="has-text-weight-bold">Use of the HoloLens2 Mixed Reality Headset for Protecting Health Care Workers During the COVID-19 Pandemic: Prospective, Observational Evaluation.</a>
            </a>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7365623/pdf/main.pdf" className="has-text-weight-bold">Practice patterns of diagnostic upper gastrointestinal endoscopy during the initial COVID-19 outbreak in England</a>
            </a>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://www.thelancet.com/journals/landig/article/PIIS2589-7500(20)30144-8/fulltext" className="has-text-weight-bold">Observational study of UK mobile health apps for COVID-19</a>
            </a>
    <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://www.hsj.co.uk/7027220.article" className="has-text-weight-bold">PanSurg Collaborative: The three vital lessons Italian hospitals have learned in fighting covid-19</a>
            </a>
            <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://www.escp.eu.com/news/2073-pansurg-project" className="has-text-weight-bold">PanSurg Collaborative: Safer surgical care during the COVID-19 pandemic</a>
            </a>
            <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://journals.lww.com/annalsofsurgery/Documents/Changing%20the%20Paradigm%20of%20Surgical%20Research%20during%20a%20Pandemic.pdf" className="has-text-weight-bold">PanSurg Collaborative: Changing the Paradigm of Surgical Research during a Pandemic</a>
            </a>
            <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://www.medrxiv.org/content/10.1101/2020.04.29.20085183v1" className="has-text-weight-bold">PanSurg Collaborative: How should hospitals manage the backlog of patients awaiting surgery following the COVID-19 pandemic? A demand modelling simulation case study for carotid endarterectomy | medRxiv</a>
            </a>
            <a className="panel-block">   
          <span className="panel-icon">
        <FaBook /></span>       
          <a href="https://wwwf.imperial.ac.uk/blog/ighi/2020/05/14/surgical-care-during-covid-19/" className="has-text-weight-bold">Rising to the challenges in frontline surgical care during COVID-19</a>
            </a>
    </TabPanel>

    <TabPanel>
      <div className="box">
        Thanks to our collaboration with  <a href=" https://www.jnjmedicaldevices.com/en-GB">J&J</a>, we are proud to present the first report of our Live Peer Review; Aftershock.
        <br />
        Click the image below to downlaod the report!
        <br />
        <br />
        <a href={aftershock} download="aftershock_report.pdf" className="has-text-weight-bold"><img className="bounce" src={aftershockimage}></img></a>
        </div>
    </TabPanel>

    <TabPanel>
    <div className="box has-background-white-ter">
<a className="subtitle has-text-weight-bold">PanSurg: Who we are</a><br /><br /><div style={{width:"100%", height:300}}>
<iframe src="https://www.youtube.com/embed/9Ta-kt2tpa0" style={{width: "100%", height: "100%"}}></iframe></div>
</div>
  <div className="box has-background-white-ter">
<a className="subtitle has-text-weight-bold">PanSurg; The Story So Far</a><br /><br /><div style={{width:"100%", height:300}}>
<iframe src="https://www.youtube.com/embed/8usziAtwoFs" style={{width: "100%", height: "100%"}}></iframe></div>
</div>
<div className="box has-background-white-ter">
<a className="subtitle has-text-weight-bold">Microsoft Hololens</a><br /><br /><div style={{width:"100%", height:300}}>
<iframe src="https://www.youtube.com/embed/iKh5E7XKUn8" style={{width: "100%", height: "100%"}}></iframe></div>
</div>
  <div className="box has-background-white-ter">
<a className="subtitle has-text-weight-bold">Sterile PPE donning and Doffing</a><br /><br /><div style={{width:"100%", height:300}}>
<iframe src="https://www.youtube.com/embed/1M9lWuLzV28" style={{width: "100%", height: "100%"}}></iframe></div>
</div>
<br />
</TabPanel>
<TabPanel>
  <Linkpreview />
</TabPanel>
  <br /> 
  </TabPanels>
  </Tabs>
</nav>
        
  )
}

export default Libdata 
