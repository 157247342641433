import * as React from 'react'
import react, {Component} from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import Helmet from 'react-helmet'
import Libdata from '../components/buildlibrary'
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "books_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`

export default ({data}) => {

  return (
  <>
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList", 
      "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "PanSurg",
      "item": "http://www.pansurg.org"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Resources",
      "item": "http://www.pansurg.org/resources"  
    }] }} />
  <GatsbySeo title='PanSurg Resources'
      description='Protocol, policies and research to help manage surgical patients and
      protect healthcare professionals.'/>
  <Hero title="Useful resources"
        subtitle="Protocol, policies and research to help manage surgical patients and
        protect healthcare professionals."
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'Resources'
  />

  <section className="section" style={{margin:'2rem 0rem'}}>
    <div className="container">
      <div className="content is-thin">
      <p>PanSurg is collating a library of useful policy documents,
        protocols and research articles from around the world. We aim to provide a useful for resource for
        surgeons and other professionals managing surgical patients and their own workforce on the frontline.</p>
      </div>
    </div>
    <div className="is-thin">
    <br />
    <div className="notification ">
                        <h3 className="subtitle is-size-5" style={{color:"red"}}>UPDATE - Aftershock Report now available!</h3>                         
          <div>
                      <p style={{float:"none"}}>We are pleased to announce the Live Peer Review Report; <b>Aftershock</b>, is now available to view under the Live Peer Reviews Tab!
                          </p>   
                          </div>       
                          </div>
    <hr className="is-divider"/>
    <br />
    <Libdata />
    </div>
  </section>

  </>
  )
}
